var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Input',{attrs:{"label":"Adı Soyadı *","defaultValue":_vm.selectedItem.fullName,"state":_vm.validateState('fullName')},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}}),_c('Input',{attrs:{"label":"Email *","defaultValue":_vm.selectedItem.email,"state":_vm.validateState('email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.selectedItem == '')?_c('Input',{attrs:{"label":"Şifre"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}):_vm._e(),_c('Input',{attrs:{"label":"Telefon","defaultValue":_vm.selectedItem.phone},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('Checkbox',{attrs:{"formGroupLabel":"Durum *","label":"Aktif","defaultValue":_vm.selectedItem.isActive === true || _vm.selectedItem.isActive === false
        ? _vm.selectedItem.isActive.toString()
        : ''},model:{value:(_vm.form.isActive),callback:function ($$v) {_vm.$set(_vm.form, "isActive", $$v)},expression:"form.isActive"}}),_c('Select',{attrs:{"label":"Rol *","defaultValue":_vm.selectedItem.userType,"state":_vm.validateState('userType'),"options":[
      {
        value: 'SystemAdministrator',
        text: _vm.$customFunctions.getUserTypeText('SystemAdministrator'),
      },
      {
        value: 'SystemSalesPerson',
        text: _vm.$customFunctions.getUserTypeText('SystemSalesPerson'),
      },
    ]},model:{value:(_vm.form.userType),callback:function ($$v) {_vm.$set(_vm.form, "userType", $$v)},expression:"form.userType"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }